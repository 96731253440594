<template>
  <RevDialog
    :close-button-label="i18n(translations.closeButton)"
    :name="MODAL_NAMES.VISIBLE_BY_VERIZON_PROMO_CODE"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="space-y-16">
        <div class="flex justify-center py-16">
          <RevIllustration
            alt=""
            :height="48"
            src="/img/visible-by-verizon/logo-with-backmarket-blue.svg"
            :width="258"
          />
        </div>

        <div class="space-y-8">
          <p class="text-static-default-hi body-1">
            {{ i18n(translations.promoCodeTitle) }}
          </p>

          <PromoCodeField
            :promo-code
            :tracking="{ zone: 'visible_popup', name: 'visible_copy_code' }"
          />
        </div>

        <p class="text-static-default-mid body-1">
          {{
            withVisiblePremium
              ? i18n(translations.withPremiumOfferContent)
              : i18n(translations.offerContent)
          }}
        </p>
      </div>
    </template>

    <template #footer>
      <div class="space-y-8">
        <RevButton
          full-width="always"
          target="_blank"
          to="https://www.visible.com/back-market"
          :tracking="{ name: 'visible_popup', zone: 'visible_get_started' }"
          variant="primary"
        >
          {{ i18n(translations.getStartedButton) }}
        </RevButton>
        <RevButton
          full-width="always"
          :tracking="{ name: 'visible_popup', zone: 'visible_close_popup' }"
          variant="secondary"
          @click="closeModal(MODAL_NAMES.VISIBLE_BY_VERIZON_PROMO_CODE)"
        >
          {{ i18n(translations.closeButton) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'
import { closeModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'

import PromoCodeField from '../PromoCodeField/PromoCodeField.vue'

import translations from './VisibleByVerizonDialog.translations'

const i18n = useI18n()
const experiments = useExperiments()

defineProps<{
  promoCode: string
}>()

const withVisiblePremium = computed(() => {
  return experiments['experiment.visiblePremium'] === 'withPremiumOffer'
})
</script>
