import type { InsuranceOffer } from '@backmarket/http-api'
import {
  getShoppingSession,
  postShoppingSession,
} from '@backmarket/http-api/src/api-specs-checkout/checkout/checkout'
import type { Order } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'

export function getCheapestInsuranceOffer(
  offers: Array<InsuranceOffer | null>,
) {
  return offers.reduce((cheapestOffer, currentOffer) => {
    if (!currentOffer) return cheapestOffer
    if (!cheapestOffer) return currentOffer

    return parseFloat(currentOffer.price.amount) <
      parseFloat(cheapestOffer.price.amount)
      ? currentOffer
      : cheapestOffer
  }, null)
}

export const dayInMilliseconds = 24 * 60 * 60 * 1000
const thirtyDaysInMilliseconds = 30 * dayInMilliseconds
export const thirtyDaysFromToday = new Date(
  Date.now() + thirtyDaysInMilliseconds,
)

export function hasBeenCreatedMoreThan30DaysAgo(
  orderline: Order['orderlines'][number],
) {
  return (
    new Date(orderline.creationDate) <
    new Date(Date.now() - thirtyDaysInMilliseconds)
  )
}

export function hasAlreadyBoughtInsurance(
  orderline: Order['orderlines'][number],
) {
  return orderline.coverage.insurancePolicies.length > 0
}

export function getOrderlinesWithoutInsurance(orders: Order[]) {
  const orderlines: Order['orderlines'] = []

  orders.forEach((order) => {
    order.orderlines.forEach((orderline) => {
      if (!hasAlreadyBoughtInsurance(orderline)) orderlines.push(orderline)
    })
  })

  return orderlines
}

export async function getPostPurchaseInsuranceOffers(
  publicId: string,
): Promise<InsuranceOffer[]> {
  try {
    const { shoppingSessionId } = await $httpFetch(postShoppingSession, {
      body: {
        type: 'INSURANCE_POST_PURCHASE',
        id: publicId,
      },
    })

    const shoppingSession = await $httpFetch(getShoppingSession, {
      pathParams: { sessionId: shoppingSessionId },
    })

    return shoppingSession.insurance.offers
  } catch {
    return []
  }
}
