<template>
  <RevCard class="p-16">
    <ItemWithIcon :icon="IconCheckInCircle">
      {{ i18n(translations.mobilePlanConfirmation) }}
    </ItemWithIcon>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import ItemWithIcon from '../../../components/ItemWithIcon/ItemWithIcon.vue'

import translations from './BouyguesMobilePlanConfirmationItem.translations'

const i18n = useI18n()
</script>
