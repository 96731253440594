export default {
  forModel: {
    id: 'insurance_summary_for_product',
    defaultMessage: 'For {model}',
  },
  includes: {
    id: 'insurance_summary_includes',
    defaultMessage: 'Includes:',
  },
  coverageBegins: {
    id: 'insurance_summary_coverage_begins',
    defaultMessage: 'Coverage begins',
  },
  coverageExpires: {
    id: 'insurance_summary_expires',
    defaultMessage: 'Expires on',
  },
}
