<template>
  <RevCard class="space-y-16 p-16">
    <ItemWithIcon :icon="IconMail">
      {{ i18n(translations.emailSent) }}
      <span class="body-2-bold overflow-wrap-anywhere break-words" data-cs-mask>
        {{ clientEmail }}
      </span>
    </ItemWithIcon>

    <ItemWithIcon :icon="IconSearch">
      {{ i18n(translations.checkSpams) }}
    </ItemWithIcon>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { IconMail } from '@ds/icons/IconMail'
import { IconSearch } from '@ds/icons/IconSearch'

import ItemWithIcon from '../../ItemWithIcon/ItemWithIcon.vue'

import translations from './OrderDetailsItem.translations'

const i18n = useI18n()

defineProps<{
  clientEmail: string
}>()
</script>
