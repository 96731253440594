export default {
  title: {
    id: 'rakuten_dialog_title',
    defaultMessage: "Don't forget to save on mobile",
  },
  promoCodeTitle: {
    id: 'rakuten_dialog_promo_code_title',
    defaultMessage: 'Use your Back Market order ID below:',
  },
  content: {
    id: 'rakuten_dialog_content',
    defaultMessage:
      'Unlock your Rakuten Mobile loyalty points on the Rakuten website.',
  },
  getStartedButton: {
    id: 'rakuten_dialog_get_started_button',
    defaultMessage: 'Get started',
  },
  closeButton: {
    id: 'rakuten_dialog_close_button',
    defaultMessage: 'Close',
  },
  rakutenLink: {
    id: 'rakuten_dialog_link',
    defaultMessage: 'https://www.rakuten.co.jp/',
  },
}
