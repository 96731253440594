export default {
  addMonthlyProtectionPlan: {
    id: 'new_payment_result_success_add_protection_plan_monthly_price',
    defaultMessage: 'Add a Protection Plan as low as {monthlyPrice}/month',
  },
  addProtectionPlan: {
    id: 'new_payment_result_success_add_protection_plan_upfront_price',
    defaultMessage: 'Add a Protection Plan as low as {price}',
  },
  getCoverage: {
    id: 'new_payment_result_success_get_coverage',
    defaultMessage: 'Protect your device, and your peace of mind.',
  },
  coverageDescription: {
    id: 'new_payment_result_success_coverage_description',
    defaultMessage: `Ever dropped your phone? Spilled coffee on your tech? Get covered in less than 2 minutes.

Offer ends: {deadline, date, short}`,
  },
  seePlans: {
    id: 'new_payment_result_success_see_plans',
    defaultMessage: 'See Protection Plans',
  },
}
