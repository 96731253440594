<template>
  <RevCard class="p-24">
    <div class="space-y-16">
      <div class="flex">
        <div class="border-static-default-low rounded-md mr-16 border p-7">
          <BouyguesLogo />
        </div>

        <div v-if="offer" class="flex grow justify-between md:items-end">
          <div class="flex flex-col">
            <p class="body-2-bold">{{ offer.label }}</p>
            <p class="body-2">
              <EnrollmentPeriod
                :enrollment-period="offer.enrollmentPeriod"
                :enrollment-translation="translations.engagementPeriod"
              />
            </p>
          </div>
          <p class="body-2-bold text-wrap">
            {{
              i18n(translations.monthlyPrice, {
                price: i18n.price(offer.prices[0].price),
              })
            }}
          </p>
        </div>
        <p v-if="!offer" class="body-2-bold">
          {{ i18n(translations.mobilePlanTitle) }}
        </p>
      </div>

      <RevDivider />

      <div class="flex items-end justify-between">
        <div class="flex flex-col">
          <p class="caption">{{ i18n(translations.simShippingLabel) }}</p>
          <p class="body-2">{{ i18n(translations.shippingTime) }}</p>
        </div>
        <p v-if="offer" class="body-2-bold">
          {{ i18n(translations.shippingPrice) }}
        </p>
      </div>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import type { PaymentResultMobilePlanOffer } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'

import BouyguesLogo from '~/components/BouyguesLogo/BouyguesLogo.vue'
import EnrollmentPeriod from '~/scopes/checkout/components/EnrollmentPeriod/EnrollmentPeriod.vue'

import translations from './BouyguesSummaryCard.translations'

const i18n = useI18n()

defineProps<{
  offer?: PaymentResultMobilePlanOffer | null
}>()
</script>
