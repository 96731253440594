<template>
  <ClientOnly>
    <component
      :is="component"
      v-if="isEnabled"
      data-test="external-reviews-component"
    />
  </ClientOnly>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import {
  Breakpoint,
  getCurrentBreakpoint,
} from '@backmarket/utils/dom/getCurrentBreakpoint'

import TrustedShopsBadge from './TrustedShops/TrustedShopsBadge.vue'
import TrustpilotBadge from './Trustpilot/TrustpilotBadge.vue'
import { useTrustmark } from './useTrustmark'

const runtimeConfig = useRuntimeConfig()
const { withTrustedShops, withTrustpilot } = useTrustmark()

const isEnabled = computed(
  () =>
    !runtimeConfig.public.KILL_TRUSTMARK &&
    getCurrentBreakpoint() > Breakpoint.MD,
)

const component = computed(() => {
  if (withTrustedShops) {
    return TrustedShopsBadge
  }
  if (withTrustpilot) {
    return TrustpilotBadge
  }

  return null
})
</script>
