export default {
  simShippingLabel: {
    id: 'bouygues_summary_card_sim_shipping_label',
    defaultMessage: 'SIM card shipping by Bouygues Telecom',
  },
  shippingTime: {
    id: 'bouygues_summary_card_shipping_time',
    defaultMessage: '1 week',
  },
  shippingPrice: {
    id: 'bouygues_summary_shipping_price',
    defaultMessage: 'Free',
  },
  monthlyPrice: {
    id: 'bouygues_summary_card_monthly_price',
    defaultMessage: 'from {price}/mo',
  },
  mobilePlanTitle: {
    id: 'bouygues_summary_card_mobile_plan_title',
    defaultMessage: 'Your mobile plan',
  },
  engagementPeriod: {
    id: 'bouygues_summary_card_engagement_period',
    defaultMessage: '{duration} engagement',
  },
}
