<template>
  <RevDialog
    :close-button-label="i18n(translations.closeButton)"
    :name="MODAL_NAMES.RAKUTEN_PROMO_CODE"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="space-y-16">
        <div class="flex justify-center">
          <RevIllustration
            alt=""
            :height="103"
            src="/img/rakuten/rakuten-logo-pink.svg"
            :width="327"
          />
        </div>

        <div class="space-y-8">
          <p class="text-static-default-hi body-1">
            {{ i18n(translations.promoCodeTitle) }}
          </p>

          <PromoCodeField
            :promo-code
            :tracking="{ zone: 'rakuten_popup', name: 'rakuten_copy_code' }"
          />
        </div>

        <p class="text-static-default-mid body-1">
          {{ i18n(translations.content) }}
        </p>
      </div>
    </template>

    <template #footer>
      <div class="space-y-8">
        <RevButton
          full-width="always"
          target="_blank"
          :to="i18n(translations.rakutenLink)"
          :tracking="{ name: 'rakuten_popup', zone: 'rakuten_get_started' }"
          variant="primary"
        >
          {{ i18n(translations.getStartedButton) }}
        </RevButton>
        <RevButton
          full-width="always"
          :tracking="{ name: 'rakuten_popup', zone: 'rakuten_close_popup' }"
          variant="secondary"
          @click="closeModal(MODAL_NAMES.RAKUTEN_PROMO_CODE)"
        >
          {{ i18n(translations.closeButton) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'
import { closeModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'

import PromoCodeField from '../PromoCodeField/PromoCodeField.vue'

import translations from './RakutenDialog.translations'

const i18n = useI18n()

defineProps<{
  promoCode: string
}>()
</script>
