import type { Order } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'

export function getRakutenPromoCode(orders: Order[]): string | null {
  const firstOrderWithRakutenPromoCode = orders.find((order) =>
    order.orderlines.some(
      (orderline) => orderline.partnerPromoCode?.partnerName === 'RAKUTEN',
    ),
  )

  const rakutenPromoCode =
    firstOrderWithRakutenPromoCode === undefined
      ? null
      : firstOrderWithRakutenPromoCode.id.toString()

  return rakutenPromoCode
}
