<template>
  <div>
    <PaymentSuccessHeader
      class="bg-static-success-low"
      illustrationPath="/img/payment/paymentResultThankYou.svg"
      :paragraph="headerDescriptionDefinition"
      :payment
      :subtitle="i18n(translations.subtitle)"
      :title="i18n(translations.title)"
    />

    <RevContainer class="mt-40">
      <span class="heading-3">
        {{ i18n(translations.steps) }}
      </span>
      <div class="mt-24 space-y-16 md:columns-2">
        <OrderDetailsItem
          v-if="props.payment?.client?.email"
          class="break-inside-avoid"
          :client-email="props.payment.client.email"
        />
        <TrackYourPackageItem class="break-inside-avoid" />
        <ErrorBoundary :owners="['bot-squad-b2cservices-front']">
          <GetCoverageItem
            class="break-inside-avoid"
            :orders="props.payment.orders"
          />
        </ErrorBoundary>
      </div>
    </RevContainer>

    <PromoCodeCard :orders="payment.orders" />

    <RevContainer v-if="!isEmpty(payment.sourcingOrders)">
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <SourcingOrdersNextSteps :sourcing-orders="payment.sourcingOrders" />

    <RevContainer>
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <OrderSection :payment />

    <RevContainer>
      <RevDivider class="my-36 md:my-56" />
    </RevContainer>

    <PaymentSuccessFooter :payment />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import ErrorBoundary from '@backmarket/nuxt-module-logger/components/ErrorBoundary.vue'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'

import OrderSection from '../components/OrderSection/OrderSection.vue'
import PaymentSuccessFooter from '../components/PaymentSuccessFooter/PaymentSuccessFooter.vue'
import PaymentSuccessHeader from '../components/PaymentSuccessHeader/PaymentSuccessHeader.vue'
import PromoCodeCard from '../components/PromoCodeCard/PromoCodeCard.vue'
import SourcingOrdersNextSteps from '../components/SourcingOrdersNextSteps/SourcingOrdersNextSteps.vue'
import GetCoverageItem from '../components/next-steps-items/GetCoverageItem/GetCoverageItem.vue'
import OrderDetailsItem from '../components/next-steps-items/OrderDetailsItem/OrderDetailsItem.vue'
import TrackYourPackageItem from '../components/next-steps-items/TrackYourPackageItem/TrackYourPackageItem.vue'

import translations from './PaymentSuccess.translations'

const i18n = useI18n()

const props = defineProps<{
  payment: PaymentResult
}>()

const headerDescriptionDefinition = computed(() =>
  props.payment.voucher
    ? translations.voucherDescription
    : translations.description,
)
</script>
