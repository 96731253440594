<template>
  <PaymentSuccessHeaderBase
    :illustration-path="props.illustrationPath"
    :subtitle="props.subtitle"
    :title="props.title"
  >
    <template #content>
      <ResultVoucherCard
        v-if="payment.voucher"
        :payment-method="payment.method as PaymentMethod"
        :voucher="payment.voucher"
      />
      <div class="body-1 mb-16">
        <FormattedMessage :definition="paragraph">
          <template #link
            ><RevLink target="_blank" :to="firstMerchant">{{
              i18n(translations.merchants, {
                merchantCount: merchantsNames.length,
              })
            }}</RevLink>
          </template>
          <template #merchants>{{ merchantsNames.join(', ') }}</template>
        </FormattedMessage>
      </div>
    </template>
    <template #aside>
      <slot name="aside" />
    </template>
  </PaymentSuccessHeaderBase>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import PaymentSuccessHeaderBase from '../PaymentSuccessHeaderBase/PaymentSuccessHeaderBase.vue'

import translations from './PaymentSuccessHeader.translations'
import ResultVoucherCard from './components/ResultVoucherCard/ResultVoucherCard.vue'

const i18n = useI18n()

const props = defineProps<{
  title: string
  subtitle: string
  paragraph: I18nDefinition<string>
  illustrationPath: string
  payment: PaymentResult
}>()

const merchantsNames = computed(() => {
  if (!props.payment.orders) return []

  return props.payment.orders.map((order) => order.merchant.name)
})

const firstMerchant = computed(() => {
  const merchants = props.payment.orders.map((order) => order.merchant)

  return merchants.length > 0 ? merchants[0].link : {}
})
</script>
