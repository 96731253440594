<template>
  <div class="fixed bottom-0 right-0 mb-20 mr-20">
    <div
      v-if="configuration"
      ref="trustpilotWidgetRef"
      :class="['bg-action-default-low rounded-sm p-16', { hidden: !isLoaded }]"
      data-test="trustpilot-badge"
      v-bind="configuration"
    >
      <RevLink
        data-test="trustpilot-fallback-link"
        rel="noopener"
        target="_blank"
        :to="configuration?.href"
      >
        Trustpilot
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { RevLink } from '@ds/components/Link'

import { useTrustpilot } from './useTrustpilot'

const trustpilotWidgetRef = ref<HTMLElement>()
const { configuration, isLoaded } = useTrustpilot(trustpilotWidgetRef)
</script>
