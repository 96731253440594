<template>
  <div
    class="bg-surface-default-hi border-static-default-low rounded-md flex items-center justify-between border py-8 pl-12 pr-8"
  >
    <p class="text-static-default-hi heading-3">{{ promoCode }}</p>

    <RevButton
      full-width="never"
      size="small"
      :tracking
      variant="secondary"
      @click="copyToClipboard"
    >
      {{ i18n(translations.copyButton) }}
    </RevButton>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'

import translations from './PromoCodeField.translations'

const props = defineProps<{
  promoCode: string
  tracking: {
    zone: string
    name: string
  }
}>()

const i18n = useI18n()
const { openSuccessToast } = useTheToast()

function copyToClipboard() {
  navigator.clipboard.writeText(props.promoCode)
  openSuccessToast({ title: i18n(translations.copied) })
}
</script>
