export default {
  subtitle: {
    id: 'new_payment_result_success_subtitle',
    defaultMessage: 'And There you go!',
  },
  title: {
    id: 'new_payment_result_success_title',
    defaultMessage: 'Deal',
  },
  description: {
    id: 'payment_result_success_description',
    defaultMessage:
      'Thank you for your trust. Once your payment is confirmed, your order will be carefully prepared and sent by our {link, html} {merchants, html}.',
  },
  voucherDescription: {
    id: 'payment_result_voucher_whats_next',
    defaultMessage:
      'Once your payment is confirmed, your order will be carefully prepared and sent by our {link, html} {merchants, html}.',
  },
  steps: {
    id: 'new_payment_result_success_steps',
    defaultMessage: 'Here are the next steps',
  },
}
