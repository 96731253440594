export default {
  title: {
    id: 'visible_by_verizon_card_title',
    defaultMessage: 'Get exclusive savings on Verizon',
  },
  offerContent: {
    id: 'visible_by_verizon_card_offer_content',
    defaultMessage:
      "Get exclusive pricing on the Visible plan, just $20/month for no-contract unlimited data on Verizon's network.",
  },
  withPremiumOfferContent: {
    id: 'visible_by_verizon_card_premium_offer_content',
    defaultMessage:
      'This device comes with an offer for unlimited 5G & 4G LTE data on the award-winning Visible by Verizon 5G network. No contract, no strings attached.',
  },
  promoCodeTitle: {
    id: 'visible_by_verizon_card_promo_code_title',
    defaultMessage: 'Save on your mobile plan with this code:',
  },
  getStartedButton: {
    id: 'visible_by_verizon_card_get_started_button',
    defaultMessage: 'See Visible plan',
  },
}
