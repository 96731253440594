<template>
  <RevContainer
    v-if="visibleByVerizonPromoCode || rakutenPromoCode"
    class="mt-56"
  >
    <template v-if="visibleByVerizonPromoCode">
      <VisibleByVerizonCard :promo-code="visibleByVerizonPromoCode" />
      <VisibleByVerizonDialog :promo-code="visibleByVerizonPromoCode" />
    </template>

    <template v-if="rakutenPromoCode">
      <RakutenCard :promo-code="rakutenPromoCode" />
      <RakutenDialog :promo-code="rakutenPromoCode" />
    </template>
  </RevContainer>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'

import type { Order } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { RevContainer } from '@ds/components/Container'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'

import RakutenCard from './components/RakutenCard/RakutenCard.vue'
import RakutenDialog from './components/RakutenDialog/RakutenDialog.vue'
import VisibleByVerizonCard from './components/VisibleByVerizonCard/VisibleByVerizonCard.vue'
import VisibleByVerizonDialog from './components/VisibleByVerizonDialog/VisibleByVerizonDialog.vue'
import { getRakutenPromoCode } from './utils/getRakutenPromoCode'
import { getVisibleByVerizonPromoCode } from './utils/getVisibleByVerizonPromoCode'

const props = defineProps<{
  orders: Order[]
}>()

const visibleByVerizonPromoCode = computed(() => {
  return getVisibleByVerizonPromoCode(props.orders)
})

const rakutenPromoCode = computed(() => {
  return getRakutenPromoCode(props.orders)
})

onMounted(() => {
  if (visibleByVerizonPromoCode.value) {
    setTimeout(() => {
      openModal(MODAL_NAMES.VISIBLE_BY_VERIZON_PROMO_CODE)
    }, 1500)
  }
  if (rakutenPromoCode.value) {
    setTimeout(() => {
      openModal(MODAL_NAMES.RAKUTEN_PROMO_CODE)
    }, 1500)
  }
})
</script>
