<template>
  <RevCard class="p-16">
    <ItemWithIcon :icon="IconContact">
      <FormattedMessage :definition="translations.gotQuestionslabel">
        <template #link>
          <RevLink target="_blank" :to="i18n(translations.linkToFAQ)">
            {{ i18n(translations.linkLabel) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </ItemWithIcon>
  </RevCard>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'
import { IconContact } from '@ds/icons/IconContact'

import ItemWithIcon from '../../../components/ItemWithIcon/ItemWithIcon.vue'

import translations from './GotQuestionsItem.translations'

const i18n = useI18n()
</script>
