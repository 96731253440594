<template>
  <RevCard>
    <div class="rounded-lg flex flex-col overflow-hidden md:flex-row">
      <div
        class="flex flex-row justify-between bg-[#1800FF] pt-16 md:max-w-[265px] md:flex-col md:pt-24"
      >
        <div class="text-static-light-hi heading-2 pl-24 md:px-24">
          {{ i18n(translations.title) }}
        </div>

        <!-- Mobile illustration -->
        <RevIllustration
          alt=""
          class="md:hidden"
          :height="69"
          src="/img/visible-by-verizon/character-cropped.png"
          :width="73"
        />

        <!-- Desktop illustrations -->
        <div class="hidden items-start justify-start md:flex">
          <RevIllustration
            alt=""
            :height="79"
            src="/img/visible-by-verizon/character.png"
            :width="77"
          />
          <div class="pt-16">
            <RevIllustration
              alt=""
              :height="32"
              src="/img/visible-by-verizon/visible-by-verizon-logo-payment-desktop.svg"
              :width="170"
            />
          </div>
        </div>
      </div>

      <div class="px-24 py-24 md:px-32">
        <p class="text-static-default-mid body-2">
          {{
            withVisiblePremium
              ? i18n(translations.withPremiumOfferContent)
              : i18n(translations.offerContent)
          }}
        </p>

        <p class="text-static-default-hi body-1 mb-8 mt-16">
          {{ i18n(translations.promoCodeTitle) }}
        </p>

        <PromoCodeField
          class="md:max-w-[396px]"
          :promo-code
          :tracking="{
            zone: 'visible_confirmation',
            name: 'copy_visible_code',
          }"
        />

        <RevButton
          class="mt-16 w-min md:mt-8"
          full-width="never"
          size="small"
          target="_blank"
          to="https://www.visible.com/back-market"
          :tracking="{
            zone: 'visible_confirmation',
            name: 'visible_get_started',
          }"
          variant="primary"
        >
          {{ i18n(translations.getStartedButton) }}
        </RevButton>
      </div>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import PromoCodeField from '../PromoCodeField/PromoCodeField.vue'

import translations from './VisibleByVerizonCard.translations'

const i18n = useI18n()
const experiments = useExperiments()

defineProps<{
  promoCode: string
}>()

const withVisiblePremium = computed(() => {
  return experiments['experiment.visiblePremium'] === 'withPremiumOffer'
})
</script>
