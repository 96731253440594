<template>
  <RevCard
    v-if="isLoading || cheapestEligibleInsurance"
    class="overflow-hidden"
  >
    <div class="w-full p-16">
      <ItemWithIcon :icon="IconQuality" :is-loading>
        {{ i18n(translations.getCoverage) }}
      </ItemWithIcon>
    </div>

    <RevDivider class="w-full" />

    <div
      class="bg-surface-default-mid flex flex-col gap-16 p-16 pl-52 lg:flex-row lg:items-center"
    >
      <div v-if="isLoading" class="w-[19rem] space-y-8">
        <RevSkeleton height="20px" shape="line" width="90%" />
        <RevSkeleton height="20px" shape="line" width="70%" />
        <RevSkeleton height="12px" shape="line" width="30%" />
      </div>

      <div v-else-if="cheapestEligibleInsurance" class="space-y-8">
        <p v-if="cheapestEligibleInsurance.isMonthly" class="body-1-bold">
          {{
            i18n(translations.addMonthlyProtectionPlan, {
              monthlyPrice: i18n.price(cheapestEligibleInsurance.price),
            })
          }}
        </p>
        <p v-else>
          {{
            i18n(translations.addProtectionPlan, {
              price: i18n.price(cheapestEligibleInsurance.price),
            })
          }}
        </p>

        <p class="text-static-default-low body-2 whitespace-pre-line">
          {{
            i18n(translations.coverageDescription, {
              deadline: thirtyDaysFromToday,
            })
          }}
        </p>
      </div>

      <RevSkeleton v-if="isLoading" height="28px" shape="line" width="170px" />

      <RevButton
        v-else-if="cheapestEligibleInsurance"
        size="small"
        :to="
          resolveLocalizedRoute({
            name: INSURANCE.POST_PURCHASE,
            query: { orderlineId: eligibleOrdelineId },
          })
        "
        :tracking="{
          zone: 'payment-result',
          name: 'get-coverage',
          price: cheapestEligibleInsurance.price,
        }"
        variant="primary"
      >
        {{ i18n(translations.seePlans) }}
      </RevButton>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { onMounted, ref } from 'vue'

import type { InsuranceOffer } from '@backmarket/http-api'
import type { Order } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevSkeleton } from '@ds/components/Skeleton'
import { IconQuality } from '@ds/icons/IconQuality'

import { INSURANCE } from '~/scopes/insurance/routes.constants'

import ItemWithIcon from '../../ItemWithIcon/ItemWithIcon.vue'

import translations from './GetCoverageItem.translations'
import {
  getCheapestInsuranceOffer,
  getOrderlinesWithoutInsurance,
  getPostPurchaseInsuranceOffers,
  thirtyDaysFromToday,
} from './GetCoverageItem.utils'

const i18n = useI18n()

const props = defineProps<{
  orders: Order[]
}>()

const resolveLocalizedRoute = useRouteLocationWithLocale()

const cheapestEligibleInsurance = ref<InsuranceOffer | null>(null)
const eligibleOrdelineId = ref<null | string>(null)
const isLoading = ref<boolean>(true)

onMounted(async () => {
  try {
    const orderlinesWithoutInsurance = getOrderlinesWithoutInsurance(
      props.orders,
    )

    const orderlinesEligibleToPostPurchaseOffer: {
      publicId: string
      postPurchaseInsuranceOffers: InsuranceOffer[]
    }[] = []

    await Promise.all(
      orderlinesWithoutInsurance.map(async (orderline) => {
        const postPurchaseInsuranceOffers =
          await getPostPurchaseInsuranceOffers(orderline.publicId)

        if (postPurchaseInsuranceOffers.length > 0) {
          orderlinesEligibleToPostPurchaseOffer.push({
            publicId: orderline.publicId,
            postPurchaseInsuranceOffers,
          })
        }
      }),
    )

    if (orderlinesEligibleToPostPurchaseOffer.length === 1) {
      eligibleOrdelineId.value =
        orderlinesEligibleToPostPurchaseOffer[0].publicId
      cheapestEligibleInsurance.value = await getCheapestInsuranceOffer(
        orderlinesEligibleToPostPurchaseOffer[0].postPurchaseInsuranceOffers,
      )
    }
  } finally {
    isLoading.value = false
  }
})
</script>
