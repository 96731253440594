export const BADGE_ID = 'MyCustomTrustbadge'

export const DEFAULT_WIDGET_CONFIG = {
  customBadgeHeight: '90',
  customBadgeWidth: '180',
  customElementId: BADGE_ID,
  disableResponsive: 'true',
  disableTrustbadge: 'false',
  trustCardTrigger: 'click',
  trustcardDirection: 'topLeft',
  variant: 'custom_reviews',
  yOffset: '-34',
}

declare global {
  interface Window {
    _tsConfig?: typeof DEFAULT_WIDGET_CONFIG
  }
}
