<template>
  <div v-for="source in sourcingOrders" :key="source.id">
    <RevContainer data-test="sourcing">
      <div class="space-y-24">
        <h3 class="heading-3">
          {{ i18n(translations.sourcingOrderTitle, { title: source.title }) }}
        </h3>

        <div
          v-if="source.wording"
          class="grid grid-cols-1 gap-16 md:mt-36 md:grid-cols-3"
        >
          <RevCard class="p-16">
            <ItemWithIcon
              v-if="source.wording.shipping"
              :icon="IconTruckExpress"
            >
              {{ source.wording.shipping }}
            </ItemWithIcon>
          </RevCard>
          <RevCard class="p-16">
            <ItemWithIcon v-if="source.wording.information" :icon="IconAvatar">
              {{ source.wording.information }}
            </ItemWithIcon>
          </RevCard>
          <RevCard class="p-16">
            <ItemWithIcon v-if="source.wording.payment" :icon="IconCreditCard">
              {{ source.wording.payment }}
            </ItemWithIcon>
          </RevCard>
        </div>
      </div>
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { IconAvatar } from '@ds/icons/IconAvatar'
import { IconCreditCard } from '@ds/icons/IconCreditCard'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import ItemWithIcon from '../ItemWithIcon/ItemWithIcon.vue'

import translations from './SourcingOrdersNextSteps.translations'

const i18n = useI18n()

defineProps<{
  sourcingOrders: PaymentResult['sourcingOrders']
}>()
</script>
