export default {
  grade: {
    id: 'orderline_summmary_card_grade',
    defaultMessage: 'Esthetic condition: {grade}',
  },
  quantity: {
    id: 'orderline_summmary_card_quantity',
    defaultMessage: 'Qty: {quantity}',
  },
  soldBy: {
    id: 'orderline_summmary_card_sold_by',
    defaultMessage: 'Sold by',
  },
  shipper: {
    id: 'helper_orderlines_shipper',
    defaultMessage: 'Shipper',
  },
  shipperLabel: {
    id: 'helper_orderlines_shipper_label',
    defaultMessage: '{shipper} - {delay}',
  },
  warranty: {
    id: 'helper_orderlines_warranty',
    defaultMessage: 'Defect warranty',
  },
  duration: {
    id: 'helper_orderlines_duration',
    defaultMessage: '{duration} months',
  },
  freeWarranty: {
    id: 'helper_orderlines_free_warranty',
    defaultMessage: 'free',
  },
  discount: {
    id: 'helper_orderlines_discount',
    defaultMessage: 'Discount',
  },
  salesTax: {
    id: 'helper_orderlines_sales_tax',
    defaultMessage: 'Sales tax',
  },
  insurance: {
    id: 'helper_orderlines_insurance',
    defaultMessage: 'Insurance',
  },
  bundlingDiscount: {
    id: 'orderline_summary_card_bouygues_subsidy',
    defaultMessage: 'Bundling saved you {price}',
  },
}
