export default {
  account: {
    id: 'new_payment_result_success_account',
    defaultMessage: 'your personal account',
  },
  step3: {
    id: 'new_payment_result_success_step3',
    defaultMessage:
      'Until then, follow the status of your order from {account, html} on the site or mobile app.',
  },
}
