export default {
  copyButton: {
    id: 'promo_code_field_copy_button',
    defaultMessage: 'Copy',
  },
  copied: {
    id: 'promo_code_field_copied_success_toast',
    defaultMessage: 'Copied!',
  },
}
