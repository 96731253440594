<template>
  <RevCard>
    <div class="rounded-lg flex flex-col overflow-hidden md:flex-row">
      <div
        class="flex flex-row items-center justify-between bg-[#FF008C] py-16 pl-24 md:max-w-[265px] md:flex-col md:items-start md:p-24"
      >
        <div class="text-static-light-hi heading-2">
          {{ i18n(translations.title) }}
        </div>

        <RevIllustration
          alt=""
          class="mr-18 shrink-0 md:hidden"
          :height="40"
          src="/img/rakuten/rakuten-logo-payment.svg"
          :width="75"
        />

        <RevIllustration
          alt=""
          class="hidden md:block"
          :height="48"
          src="/img/rakuten/rakuten-logo-with-back-market.svg"
          :width="170"
        />
      </div>

      <div class="px-24 py-24 md:px-32">
        <p class="text-static-default-mid body-2">
          {{ i18n(translations.content) }}
        </p>

        <p class="text-static-default-hi body-1 mb-8 mt-16">
          {{ i18n(translations.promoCodeTitle) }}
        </p>

        <PromoCodeField
          class="md:max-w-[396px]"
          :promo-code
          :tracking="{
            zone: 'rakuten_confirmation',
            name: 'copy_rakuten_code',
          }"
        />

        <RevButton
          class="mt-16 w-min md:mt-8"
          full-width="never"
          size="small"
          target="_blank"
          :to="i18n(translations.linkToRakuten)"
          :tracking="{
            zone: 'rakuten_confirmation',
            name: 'rakuten_get_started',
          }"
          variant="primary"
        >
          {{ i18n(translations.getStartedButton) }}
        </RevButton>
      </div>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import PromoCodeField from '../PromoCodeField/PromoCodeField.vue'

import translations from './RakutenCard.translations'

const i18n = useI18n()

defineProps<{
  promoCode: string
}>()
</script>
