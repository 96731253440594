export default {
  subtitle: {
    id: 'bouygues_payment_success_header_subtitle',
    defaultMessage: 'Hang tight.',
  },
  title: {
    id: 'bouygues_payment_success_header_title',
    defaultMessage: 'Your order is being confirmed.',
  },
  steps: {
    id: 'new_payment_result_success_steps',
    defaultMessage: 'Here are the next steps',
  },
  headerParagraph: {
    id: 'bouygues_payment_success_header_paragraph',
    defaultMessage:
      'Thanks for your trust! Once your payment and Bouygues Telecom plan creation are confirmed within 2 business hours, {link, html} {merchants, html} will carefully prepare and ship your order.',
  },
  asideLink: {
    id: 'bouygues_payment_success_header_aside_link',
    defaultMessage: 'Check status of Bouygues Telecom order',
  },
  asideParagraph: {
    id: 'bouygues_payment_success_header_aside_paragraph',
    defaultMessage:
      'Find the latest info on your mobile plan in your Bouygues Telecom portal.',
  },
  headerBouyguesCardTitle: {
    id: 'bouygues_payment_success_header_bouygues_card_title',
    defaultMessage: 'Check status of Bouygues Telecom order',
  },
  headerBouyguesCardContent: {
    id: 'bouygues_payment_success_header_bouygues_card_content',
    defaultMessage:
      'Find the latest info on your mobile plan in your Bouygues Telecom portal.',
  },
  headerBouyguesCardButton: {
    id: 'bouygues_payment_success_header_bouygues_card_button',
    defaultMessage: 'Go to portal',
  },
}
