export type PartnerPromoCode = {
  id: string
  partnerName: PartnerNames
}

export type PaymentSuccessPartnerPromoCode = {
  id: string
  partnerName: PartnerNames
  codeValue: string
}

export function isPaymentSuccessPartnerPromoCode(
  code: PartnerPromoCode | PaymentSuccessPartnerPromoCode,
): code is PaymentSuccessPartnerPromoCode {
  return (code as PaymentSuccessPartnerPromoCode).codeValue !== undefined
}

export type PartnerNames = 'VISIBLE' | 'RAKUTEN'
