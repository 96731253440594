export default {
  number: {
    id: 'payment_summary_card_number',
    defaultMessage: 'N°',
  },
  date: {
    id: 'payment_summary_card_date',
    defaultMessage: 'Date',
  },
  shippingAddress: {
    id: 'payment_summary_card_shipping_address',
    defaultMessage: 'Shipping address',
  },
  billingAddress: {
    id: 'payment_summary_card_billing_address',
    defaultMessage: 'Billing address',
  },
  payment: {
    id: 'payment_summary_card_payment',
    defaultMessage: 'Payment',
  },
}
