export default {
  linkLabel: {
    id: 'got_questions_item_link',
    defaultMessage: 'Get answers',
  },
  gotQuestionslabel: {
    id: 'got_questions_item_label',
    defaultMessage: 'Got questions? {link, html}',
  },
  linkToFAQ: {
    id: 'got_questions_item_link_to_FAQ',
    defaultMessage: 'https://google.fr',
  },
}
