<template>
  <RevCard class="space-y-16 p-24">
    <div class="flex">
      <RevIllustration
        alt="BackUp logo"
        class="mr-16 block self-baseline"
        :height="48"
        src="/img/insurances/upLogo.svg"
        :width="48"
      />

      <div class="w-full">
        <p class="body-2-bold mb-4" data-qa="payment-product-name">
          {{ offer.title }}
        </p>
        <p class="body-2 mb-4">
          {{ i18n(translations.forModel, { model: title }) }}
        </p>
        <div class="flex items-center justify-between">
          <p class="body-2-bold mb-4" data-qa="payment-product-price">
            {{ i18n.price(offer.price) }}
          </p>
        </div>
      </div>
    </div>
    <RevDivider />
    <div>
      <p class="caption mb-4">{{ i18n(translations.includes) }}</p>
      <ul
        v-for="line in offer.benefits?.short"
        :key="line"
        class="mb-4 ml-8 list-inside"
      >
        <li class="body-2">{{ line }}</li>
      </ul>
    </div>

    <template v-if="policy.subscriptionDate">
      <RevDivider />
      <div>
        <p class="caption mb-4">{{ i18n(translations.coverageBegins) }}</p>
        <p class="body-2">
          {{ policy.subscriptionDate }}
        </p>
      </div>
    </template>

    <template v-if="policy.expirationDate">
      <RevDivider />
      <div>
        <p class="caption mb-4">{{ i18n(translations.coverageExpires) }}</p>
        <p class="body-2">
          {{ policy.expirationDate }}
        </p>
      </div>
    </template>
  </RevCard>
</template>

<script setup lang="ts">
import type { InsuranceOffer, InsurancePolicy } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './InsuranceSummaryCard.translations'

const i18n = useI18n()

defineProps<{
  offer: InsuranceOffer
  policy: InsurancePolicy
  title: string
}>()
</script>
