export default {
  title: {
    id: 'visible_by_verizon_dialog_title',
    defaultMessage: 'Get exclusive savings on Verizon',
  },
  promoCodeTitle: {
    id: 'visible_by_verizon_dialog_promo_code_title',
    defaultMessage: 'Save on your mobile plan with this code:',
  },
  withPremiumOfferContent: {
    id: 'visible_by_verizon_dialog_with_premium_offer_content',
    defaultMessage:
      'Only on Back Market, get exclusive pricing on the award-winning Visible by Verizon 5G network. No contract, no strings attached.',
  },
  offerContent: {
    id: 'visible_by_verizon_dialog_offer_content',
    defaultMessage:
      "Get exclusive pricing on the Visible plan, just $20/month for no-contract unlimited data on Verizon's network.",
  },
  getStartedButton: {
    id: 'visible_by_verizon_dialog_get_started_button',
    defaultMessage: 'See Visible plan',
  },
  closeButton: {
    id: 'visible_by_verizon_dialog_close_button',
    defaultMessage: 'Close',
  },
}
