<template>
  <RevCard class="p-24">
    <div class="flex">
      <div
        class="border-static-default-low rounded-sm mr-16 flex h-40 w-40 items-center justify-center border"
      >
        <RevIllustration
          v-if="orderline.product"
          :alt="orderline.product.title"
          class="h-32 w-32"
          :height="32"
          :src="orderline.product.image"
          :width="32"
        />
      </div>
      <div class="w-full">
        <div
          class="body-2-bold flex justify-between"
          data-qa="payment-product-name"
        >
          <p>{{ orderline.product.title }}</p>
          <p class="body-2-bold" data-qa="payment-product-price">
            {{ i18n.price(orderline.displayPrice) }}
          </p>
        </div>
        <p class="body-2 mt-4">
          {{ i18n(translations.grade, { grade: orderline.product.gradeName }) }}
        </p>
        <div class="md:flex md:items-center md:justify-between">
          <p
            class="text-static-default-low body-2"
            data-qa="payment-product-quantity"
          >
            {{ i18n(translations.quantity, { quantity: orderline.quantity }) }}
          </p>
          <p
            v-if="subsidyAbsoluteValue"
            class="text-static-success-hi caption mt-8 md:m-0"
          >
            {{
              i18n(translations.bundlingDiscount, {
                price: i18n.price(subsidyAbsoluteValue),
              })
            }}
          </p>
        </div>
      </div>
    </div>

    <OrderlineSummaryOption
      v-for="line in lines"
      :key="line?.title"
      :price="line?.price"
      :subtitle="line?.subtitle"
      :title="line?.title"
    />

    <OrderlineSummaryOption :title="i18n(translations.soldBy)">
      <div class="mt-8 flex items-center">
        <RevLink target="_blank" :to="merchant.link">
          <div class="body-2-bold">
            {{ merchant.name }}
          </div>
        </RevLink>
        <RevRating
          class="ml-8"
          :score="merchant.reviews.averageRate"
          size="small"
        />
      </div>
    </OrderlineSummaryOption>
  </RevCard>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type Price } from '@backmarket/http-api'
import {
  type Merchant,
  type Orderline,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevRating } from '@ds/components/Rating'

import { useFormatHours } from '~/scopes/checkout/composables/useFormatHours'

import translations from './OrderlineSummaryCard.translations'
import OrderlineSummaryOption from './components/OrderlineSummaryOption.vue'

const i18n = useI18n()
const { getFormatHoursInDay } = useFormatHours()

const props = defineProps<{
  discountToken?: string
  orderline: Orderline
  merchant: Merchant
  subsidy?: Price
}>()

const subsidyAbsoluteValue = computed(() => {
  if (isEmpty(props.subsidy)) {
    return null
  }

  if (props.subsidy.amount[0] === '-') {
    return {
      amount: props.subsidy.amount.slice(1),
      currency: props.subsidy.currency,
    }
  }

  return props.subsidy
})

const shippingOption = computed(() => {
  const { priceShipping, shipping } = props.orderline
  if (!shipping) {
    return null
  }

  return {
    title: i18n(translations.shipper),
    subtitle: i18n(translations.shipperLabel, {
      shipper: shipping.carrierName,
      delay: getFormatHoursInDay(shipping.deliveryDelay),
    }),
    price: i18n.price(priceShipping),
  }
})

const warrantyOption = computed(() => {
  const { coverage } = props.orderline

  if (
    isEmpty(coverage) ||
    (isEmpty(coverage.merchantWarranty) && isEmpty(coverage.backmarketWarranty))
  ) {
    return null
  }

  const { price } = coverage.merchantWarranty

  return {
    title: i18n(translations.warranty),
    subtitle: i18n(translations.duration, {
      duration:
        (coverage?.merchantWarranty?.duration ?? 0) +
        (coverage?.backmarketWarranty?.duration ?? 0),
    }),
    price: isEmpty(price) ? i18n(translations.freeWarranty) : i18n.price(price),
  }
})

const insurancesOptions = computed(() => {
  const { coverage } = props.orderline

  if (!coverage || isEmpty(coverage.insurancePolicies)) {
    return []
  }

  return coverage.insurancePolicies.map((insurance) => {
    return {
      id: `insurance-option-${insurance.id}`,
      title: i18n(translations.insurance),
      subtitle: insurance.offerTitle,
      price: i18n.price(insurance.price),
    }
  })
})

const discountOption = computed(() => {
  const { discount } = props.orderline

  if (discount && discount.amount === '0.00') {
    return null
  }

  return {
    title: i18n(translations.discount),
    subtitle: props.discountToken,
    price: `-${i18n.price(discount)}`,
  }
})

const salesTaxOption = computed(() => {
  const { priceSalesTax } = props.orderline

  if (priceSalesTax && priceSalesTax.amount === '0.00') {
    return null
  }

  return {
    title: i18n(translations.salesTax),
    subtitle: i18n(translations.salesTax),
    price: i18n.price(priceSalesTax),
  }
})

const lines = computed(() =>
  [
    shippingOption.value,
    warrantyOption.value,
    ...insurancesOptions.value,
    discountOption.value,
    salesTaxOption.value,
  ].filter((v) => !isEmpty(v)),
)
</script>
