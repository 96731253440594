export default {
  title: {
    id: 'rakuten_card_title',
    defaultMessage: "Don't forget to save on a new mobile plan.",
  },
  content: {
    id: 'rakuten_card_content',
    defaultMessage:
      'Unlock your Rakuten Mobile loyalty points on the Rakuten website.',
  },
  promoCodeTitle: {
    id: 'rakuten_card_promo_code_title',
    defaultMessage: 'Use your Back Market order ID below:',
  },
  getStartedButton: {
    id: 'rakuten_card_get_started_button',
    defaultMessage: 'Get started',
  },
  linkToRakuten: {
    id: 'rakuten_card_link_to_rakuten',
    defaultMessage: 'https://network.mobile.rakuten.co.jp/',
  },
}
