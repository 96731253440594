export default {
  emailSent: {
    id: 'new_payment_result_success_step1',
    defaultMessage:
      'We will send you the details of your order by email to the address:',
  },
  checkSpams: {
    id: 'new_payment_result_success_step2',
    defaultMessage:
      'Remember to watch your spam and within a few days, we will ask you for news, just to see if you are satisfied.',
  },
}
