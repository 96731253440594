<template>
  <div class="body-1">
    <FormattedMessage :definition="translations.description">
      <template #date>
        <span class="body-1-bold">{{ formattedDate }}</span>
      </template>
    </FormattedMessage>

    <ul class="my-7 list-none">
      <li>
        <span class="body-1-bold">{{ i18n(translations.codeLabel) }}</span>
        {{ voucher.code }}
      </li>
      <li>
        <span class="body-1-bold">{{ i18n(translations.phoneLabel) }}</span>
        {{ voucher.phoneNumber }}
      </li>
      <li>
        <span class="body-1-bold">
          {{ i18n(translations.networksLabel) }}
        </span>
        {{ i18n.list(networkNames) }}
        <RevLink target="_blank" :to="voucher.instructionsUrl">{{
          i18n(translations.instructionsLink)
        }}</RevLink>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type {
  CreatePaymentNextActionVoucher,
  PaymentMethod,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { paymentNetworkConfig } from '@backmarket/nuxt-layer-payment/methods/helpers/methods'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'

import translations from './ResultVoucherCard.translations'

const i18n = useI18n()

const props = defineProps<{
  voucher: CreatePaymentNextActionVoucher
  paymentMethod: PaymentMethod
}>()

const formattedDate = computed(() =>
  i18n.date(new Date(props.voucher.expirationDate), {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }),
)

const networkNames = computed(() =>
  props.paymentMethod.networks.map((network) => {
    const { name } = paymentNetworkConfig(network)

    return typeof name === 'string' ? name : i18n(name)
  }),
)
</script>
