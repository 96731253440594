<template>
  <RevCard class="flex gap-12 p-16">
    <ItemWithIcon :icon="IconPackage">
      <FormattedMessage :definition="translations.step3">
        <template #account>
          <RevLink
            target="_blank"
            :to="resolveLocalizedRoute({ name: DASHBOARD.SELF })"
          >
            {{ i18n(translations.account) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </ItemWithIcon>
  </RevCard>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevLink } from '@ds/components/Link'
import { IconPackage } from '@ds/icons/IconPackage'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import ItemWithIcon from '../../ItemWithIcon/ItemWithIcon.vue'

import translations from './TrackYourPackageItem.translations'

const resolveLocalizedRoute = useRouteLocationWithLocale()
const i18n = useI18n()
</script>
