export default {
  total: {
    id: 'payment_result_page_total',
    defaultMessage: 'Total (tax included)',
  },
  yourCoverageTitle: {
    id: 'insurance_summary_title',
    defaultMessage: 'Your coverage',
  },
  order: {
    id: 'payment_result_success_order',
    defaultMessage: 'Your order',
  },
}
