export default {
  description: {
    id: 'payment_result_voucher_description',
    defaultMessage:
      'Thanks for your trust! Please use the following information to complete your payment before {date, html}:',
  },
  codeLabel: {
    id: 'payment_result_voucher_code_label',
    defaultMessage: 'Payment code:',
  },
  phoneLabel: {
    id: 'payment_result_voucher_phone_label',
    defaultMessage: "Customer's phone number:",
  },
  networksLabel: {
    id: 'payment_result_voucher_networks_label',
    defaultMessage: 'Store where to complete this payment:',
  },
  instructionsLink: {
    id: 'payment_result_voucher_instructions_link',
    defaultMessage: 'Read instructions',
  },
}
