<template>
  <RevCard class="p-24">
    <p class="text-static-default-low caption">
      {{ i18n(translations.number) }}
    </p>
    <RevLink
      class="body-2-link mt-8"
      data-test="link"
      target="_blank"
      :to="resolveLocalizedRoute({ name: DASHBOARD.MY_ORDERS.SELF })"
      >{{ paymentId }}
    </RevLink>

    <RevDivider class="my-16" />

    <p class="text-static-default-low caption" data-qa="payment-date">
      {{ i18n(translations.date) }}
    </p>
    <div class="body-2 mt-8">
      {{ formattedDate }}
    </div>

    <div v-if="!isPostPurchase">
      <RevDivider class="my-16" />

      <p
        class="text-static-default-low caption"
        data-qa="payment-shipping-address"
      >
        {{ i18n(translations.shippingAddress) }}
      </p>
      <div class="body-2 mt-8">
        <Address :address="shippingAddress" with-details />
      </div>
    </div>
    <RevDivider class="my-16" />

    <p
      class="text-static-default-low caption"
      data-qa="payment-billing-address"
    >
      {{ i18n(translations.billingAddress) }}
    </p>
    <div class="body-2 mt-8">
      <Address :address="billingAddress" with-details />
    </div>

    <RevDivider class="my-16" />

    <p class="text-static-default-low caption">
      {{ i18n(translations.payment) }}
    </p>
    <div class="body-2 mt-8 flex items-center">
      <ErrorBoundary :owners="['bot-squad-payin-front']">
        <PaymentIcons
          data-qa="payment-cart-icon"
          :methods="[paymentMethod as PaymentMethod]"
          type="methods"
        />
      </ErrorBoundary>

      <span class="ml-8">{{ methodName }}</span>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import type { AddressType } from '@backmarket/http-api'
import type { PaymentMethodResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { PaymentIcons } from '@backmarket/nuxt-layer-payment/methods'
import { paymentMethodName } from '@backmarket/nuxt-layer-payment/methods/helpers/methods'
import Address from '@backmarket/nuxt-module-address/Address.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import ErrorBoundary from '@backmarket/nuxt-module-logger/components/ErrorBoundary.vue'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'
import { RevLink } from '@ds/components/Link'

import { DASHBOARD } from '~/scopes/dashboard/routes'

import translations from './SummaryCard.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const props = withDefaults(
  defineProps<{
    billingAddress: AddressType
    date: string
    isPostPurchase?: boolean
    paymentId: string
    paymentMethod: PaymentMethodResult
    shippingAddress: AddressType
  }>(),
  {
    isPostPurchase: false,
  },
)

const methodName = computed(() => paymentMethodName(props.paymentMethod.bmCode))

const formattedDate = computed(() => {
  const date = new Date(props.date)
  const format: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  return i18n.date(date, format)
})
</script>
